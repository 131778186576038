import React, { useEffect, useState } from 'react';
import { Spin, Button, Collapse, Input, message, Upload } from 'antd';
import { useParams, useHistory } from 'react-router-dom';
import { API } from '../../helpers/api';
import EvolutionTable from './EvolutionTable';
import styles from './styles.module.scss';
import EvolutionChart from './EvolutionChart';
import { UploadOutlined } from '@ant-design/icons';

const { Panel } = Collapse;
const { TextArea } = Input;

const ProfileUser = () => {
    const { id } = useParams();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const history = useHistory();
    const [username, setUsername] = useState('');
    const [bio, setBio] = useState('');
    const [fileList, setFileList] = useState([]);
    const [editing, setEditing] = useState(false);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await API({
                    url: `v1/users/admin/users/${id}`,
                    method: 'GET',
                });
                setUser(response.data);
                setUsername(response.data.nickName);
                setBio(response.data.bio);

                if (response.data.photoUrl) {
                    setFileList([
                        {
                            uid: response.data.id,
                            name: 'photo.png',
                            status: 'done',
                            url: response.data.photoUrl,
                        },
                    ]);
                }
            } catch (error) {
                console.error('Ошибка при получении данных пользователя:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchUser();
    }, [id]);

    const handleSave = async () => {
        try {
            if (fileList.length > 0 && fileList[0].originFileObj) {
                const formData = new FormData();
                formData.append('avatar', fileList[0].originFileObj);

                await API({
                    url: `v1/users/admin/personal/avatar/${id}`,
                    method: 'POST',
                    data: formData,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
            }

            await API({
                url: `v1/users/admin/users/${id}`,
                method: 'PUT',
                data: {
                    username,
                    bio: bio,
                },
            });

            message.success('Профиль обновлен успешно');
            setEditing(false);
        } catch (error) {
            message.error('Ошибка при обновлении профиля');
        }
    };

    const handleSelectPicture = ({ fileList }) => {
        const newFileList = fileList.slice(-1);
        setFileList(newFileList);
        setEditing(true);
    };

    if (loading) {
        return <Spin tip="Загрузка..." />;
    }

    const goals = user.goals || [];
    const habits = user.habits || [];
    const tasks = user.tasks || [];
    const journal = user.journal || [];

    const activeKeys = [];
    if (goals.length > 0) activeKeys.push('1');
    if (habits.length > 0) activeKeys.push('2');
    if (tasks.length > 0) activeKeys.push('3');
    if (journal.length > 0) activeKeys.push('4');

    return (
        <div className={styles.profileContainer}>
            <Button onClick={() => history.goBack()} style={{ marginBottom: '20px' }}>
                Back
            </Button>
            {user && (
                <>
                    <div className={styles.profilePhoto}>
                        <div>
                            {fileList.length > 0 && (
                                <div style={{ marginTop: 10, marginBottom: 20 }}>
                                    <img
                                        src={
                                            fileList[0].url ||
                                            URL.createObjectURL(fileList[0].originFileObj)
                                        }
                                        alt="Uploaded"
                                        style={{
                                            width: '100%',
                                            height: 'auto',
                                            maxHeight: '200px',
                                            objectFit: 'contain',
                                        }}
                                    />
                                </div>
                            )}
                        </div>

                        <Upload
                            fileList={fileList}
                            onChange={handleSelectPicture}
                            beforeUpload={() => false} // Prevent auto-upload
                            accept=".png,.jpg,.jpeg,.webp"
                        >
                            <Button icon={<UploadOutlined />}>Загрузить новый аватар</Button>
                        </Upload>
                    </div>
                    <div className={styles.profileField}>
                        <strong>Username:</strong>
                        <Input
                            value={username}
                            onChange={(e) => {
                                setUsername(e.target.value);
                                setEditing(true);
                            }}
                        />
                    </div>

                    <div className={styles.profileField}>
                        <strong>Email:</strong> <span>{user.email}</span>
                    </div>
                    <div className={styles.profileField}>
                        <strong>Phone:</strong> <span>{user.phone}</span>
                    </div>
                    <div className={styles.profileField}>
                        <strong>Plan:</strong> <span>{user.plan}</span>
                    </div>

                    <div className={styles.profileField}>
                        <strong>BIO:</strong>
                        <TextArea
                            value={bio}
                            onChange={(e) => {
                                setBio(e.target.value);
                                setEditing(true);
                            }}
                            rows={4}
                        />
                    </div>
                    {editing && (
                        <Button
                            type="primary"
                            onClick={handleSave}
                            style={{ marginBottom: '20px' }}
                        >
                            Сохранить
                        </Button>
                    )}
                </>
            )}

            <Collapse
                defaultActiveKey={activeKeys.length > 0 ? activeKeys : ['1']}
                style={{ marginTop: '20px' }}
            >
                <Panel header="Goals" key="1">
                    {goals.length > 0 ? (
                        <ol>
                            {goals.map((goal, index) => (
                                <li key={index}>{goal.name}</li>
                            ))}
                        </ol>
                    ) : (
                        <p>No goals</p>
                    )}
                </Panel>
                <Panel header="Habits" key="2">
                    {habits.length > 0 ? (
                        <ol>
                            {habits.map((habit, index) => (
                                <li key={index}>{habit.name}</li>
                            ))}
                        </ol>
                    ) : (
                        <p>No habits</p>
                    )}
                </Panel>
                <Panel header="Tasks" key="3">
                    {tasks.length > 0 ? (
                        <ol>
                            {tasks.map((task, index) => (
                                <li key={index}>{task.name}</li>
                            ))}
                        </ol>
                    ) : (
                        <p>No tasks</p>
                    )}
                </Panel>
                <Panel header="Journal" key="4">
                    {journal.length > 0 ? (
                        <ol>
                            {journal.map((task, index) => (
                                <li key={index}>
                                    {task.title} - {task.description}
                                </li>
                            ))}
                        </ol>
                    ) : (
                        <p>No entries</p>
                    )}
                </Panel>
            </Collapse>

            {/* <Divider />  */}
            {/* <EvolutionTable /> */}
            {/* <EvolutionChart data={[]} /> */}
        </div>
    );
};

export default ProfileUser;
