import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Table, Button, Modal, Space, Input, Select } from 'antd';
import { API } from '../../helpers/api';
import styles from './ChallengesList.module.scss';

const { Option } = Select;

export const WELLNESS = [
    'Emotional',
    'Environmental',
    'Physical',
    'Financial',
    'Intellectual',
    'Interpersonal',
    'Occupational',
    'Spiritual',
];

export const DURATIONS = ['month', 'week', 'long term', 'day', 'year'];

const ChallengesList = () => {
    const history = useHistory();
    const location = useLocation();
    const [challenges, setChallenges] = useState([]);
    const [isModalOpen, setModalOpen] = useState(false);
    const [deleteChallengeId, setDeleteChallengeId] = useState(null);
    const [filteredChallenges, setFilteredChallenges] = useState([]);
    const [filterText, setFilterText] = useState('');
    const [filterType, setFilterType] = useState(null);
    const [filterWellness, setFilterWellness] = useState(null);
    const [filterDuration, setFilterDuration] = useState(null);

    // Пагинация
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    useEffect(() => {
        getChallenges();
    }, []);

    useEffect(() => {
        setFilteredChallenges(
            challenges.filter((challenge) => {
                return (
                    (filterText
                        ? challenge.name.toLowerCase().includes(filterText.toLowerCase())
                        : true) &&
                    (filterType ? challenge.type === filterType : true) &&
                    (filterWellness ? challenge.wellness === filterWellness : true) &&
                    (filterDuration ? challenge.duration === filterDuration : true)
                );
            }),
        );
    }, [filterText, filterType, filterWellness, filterDuration, challenges]);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const page = parseInt(queryParams.get('page'), 10) || 1;
        setCurrentPage(page);
    }, [location.search]);

    const getChallenges = async () => {
        try {
            const { data } = await API({ url: 'v1/challenges/admin/list', method: 'GET' });
            if (data && data.challenges) {
                setChallenges(data.challenges);
                setFilteredChallenges(data.challenges);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const modalAgreeHandler = async () => {
        await API({ url: `v1/challenges/admin/${deleteChallengeId}`, method: 'DELETE' });
        getChallenges();
        setModalOpen(false);
    };

    const deleteChallenge = (id) => {
        setDeleteChallengeId(id);
        setModalOpen(true);
    };

    const handleEdit = (id) => {
        history.push(`list/${id}`);
    };

    const handleTableChange = (pagination) => {
        const { current } = pagination;
        setCurrentPage(current);
        history.push(`?page=${current}`);
    };

    return (
        <div>
            <Input
                placeholder="Filter by name"
                value={filterText}
                onChange={(e) => setFilterText(e.target.value)}
                style={{ marginBottom: '16px', marginRight: '10px', width: 300 }}
            />
            <Select
                placeholder="Filter by type"
                value={filterType}
                onChange={setFilterType}
                style={{ marginRight: '10px', width: 200 }}
            >
                <Option value={null}>All Types</Option>
                <Option value="goal">Goals</Option>
                <Option value="habit">Habits</Option>
            </Select>
            <Select
                placeholder="Filter by wellness"
                value={filterWellness}
                onChange={setFilterWellness}
                style={{ marginRight: '10px', width: 200 }}
            >
                <Option value={null}>All Wellness</Option>
                {WELLNESS.map((wellness) => (
                    <Option key={wellness} value={wellness}>
                        {wellness}
                    </Option>
                ))}
            </Select>
            <Select
                placeholder="Filter by duration"
                value={filterDuration}
                onChange={setFilterDuration}
                style={{ marginRight: '10px', width: 200 }}
            >
                <Option value={null}>All Durations</Option>
                {DURATIONS.map((duration) => (
                    <Option key={duration} value={duration}>
                        {duration}
                    </Option>
                ))}
            </Select>

            <Table
                dataSource={filteredChallenges}
                rowKey="id"
                pagination={{
                    current: currentPage,
                    pageSize: pageSize,
                    total: filteredChallenges.length,
                    showSizeChanger: false,
                }}
                onChange={handleTableChange}
                bordered
            >
                <Table.Column title="Id" dataIndex="id" width={50} />
                <Table.Column
                    title="Type"
                    dataIndex="type"
                    render={(text) => text.toUpperCase()}
                    width={120}
                />
                <Table.Column title="Name" dataIndex="name" width={400} />
                <Table.Column title="Description" dataIndex="description" width={400} />
                <Table.Column
                    title="Status"
                    render={() => (
                        <div className={styles.statusCont}>
                            <div className={styles.accept}>Accept</div>
                        </div>
                    )}
                    width={400}
                />
                <Table.Column
                    title="Action"
                    render={(text, record) => (
                        <Space size="middle">
                            <Button type="primary" onClick={() => handleEdit(record.id)}>
                                Open
                            </Button>
                            <Button type="danger" onClick={() => deleteChallenge(record.id)}>
                                Delete
                            </Button>
                        </Space>
                    )}
                    width={80}
                />
            </Table>

            <Modal
                title="Confirm Deletion"
                visible={isModalOpen}
                onOk={modalAgreeHandler}
                onCancel={() => setModalOpen(false)}
                okText="Yes"
                cancelText="No"
            >
                <p>Are you sure you want to delete this challenge?</p>
            </Modal>

            <br />
            <br />
            <br />
            <br />
            <br />
        </div>
    );
};

export { ChallengesList };
