import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { slugify } from 'transliteration';
import { Form, Input, Button, message } from 'antd';
import { API } from '../../../helpers/api';

const CreateCategory = () => {
    const [title, setTitle] = useState('');
    const [titleError, setTitleError] = useState(false);

    const history = useHistory();

    const validateFields = () => {
        if (!title) {
            setTitleError(true);
            return false;
        }
        return true;
    };

    const createCategory = async () => {
        if (!validateFields()) {
            return;
        }
        setTitleError(false);

        try {
            await API({
                url: 'v1/blog/article-categories',
                method: 'POST',
                data: {
                    title,
                    alias: slugify(title),
                },
            });
            message.success('Category created successfully!');
            history.push('/blog/article-categories');
        } catch (error) {
            message.error('Failed to create category.');
        }
    };

    return (
        <div>
            <h2>Create Category</h2>
            <br />
            <Form layout="vertical">
                <Form.Item label="Title" required>
                    <Input
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        onBlur={() => setTitleError(!title)}
                    />
                    {titleError && <div style={{ color: '#ca2d2d' }}>This field is required</div>}
                </Form.Item>

                <Form.Item>
                    <Button type="primary" onClick={createCategory}>
                        Create
                    </Button>
                    <Button style={{ marginLeft: '10px' }} onClick={() => history.goBack()}>
                        Cancel
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default CreateCategory;
