import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Table, Modal, Typography, Row, Col } from 'antd';
import { API } from '../../../helpers/api';
import { normalizeDate } from '../../../helpers/common';

const { Column } = Table;
const { confirm } = Modal;
const { Title } = Typography;

const Inspiration = () => {
    const history = useHistory();
    const [affiliates, setAffiliate] = useState([]);

    const getAffiliate = () => {
        API({ url: 'v1/journey/admin/inspiration', method: 'GET' }).then((res) => {
            setAffiliate(res.data.items.reverse());
        });
    };

    useEffect(() => {
        getAffiliate();
    }, []);

    const handleCreateAffiliate = () => {
        history.push('/inspiration/create');
    };

    const deleteInspiration = (id) => {
        confirm({
            title: 'Are you sure you want to delete this inspiration?',
            onOk() {
                API({ url: 'v1/journey/admin/inspiration/' + id, method: 'DELETE' }).then(() => {
                    getAffiliate();
                });
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    const handleEdit = (id) => {
        history.push(`edit/${id}`);
    };

    return (
        <div>
            <Row>
                <Col span={24}>
                    <Title level={2}>Inspiration</Title>
                    <Button
                        type="primary"
                        style={{ marginTop: 10, marginLeft: 10 }}
                        onClick={handleCreateAffiliate}
                    >
                        Create inspiration
                    </Button>
                </Col>
            </Row>

            <Table dataSource={affiliates} rowKey="id" pagination={false} style={{ marginTop: 20 }}>
                <Column title="Id" dataIndex="id" width={50} />
                <Column title="Author" dataIndex="title" width={150} />
                <Column
                    title="Text"
                    dataIndex="body"
                    width={700}
                    render={(text) => <div>{text}</div>}
                />
                <Column
                    title="Created"
                    dataIndex="created_at"
                    width={150}
                    render={(text) => <div>{normalizeDate(text)}</div>}
                />
                <Column
                    title="Action"
                    width={80}
                    render={(text, record) => (
                        <div style={{ display: 'flex', gap: '10px' }}>
                            <Button type="primary" onClick={() => handleEdit(record.id)}>
                                Edit
                            </Button>
                            <Button onClick={() => deleteInspiration(record.id)} danger>
                                Remove
                            </Button>
                        </div>
                    )}
                />
            </Table>

            <br />
            <br />
            <br />
            <br />
        </div>
    );
};

export default Inspiration;
