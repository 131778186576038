import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Input, Button, Typography, message } from 'antd';
import { API } from '../../../helpers/api';
import { getLastUrlPath } from '../../../helpers/getLastUrlPath';
import { STANDART_FIELD_LENGTH } from '../../../constants';

const EditAffiliate = () => {
    const [title, setTitle] = useState('');
    const [subtitle, setSubtitle] = useState('');
    const [titleError, setTitleError] = useState(false);
    const [affiliateId, setAffiliateId] = useState(null);

    const history = useHistory();

    useEffect(() => {
        API({
            url: `v1/journey/admin/prompt/${getLastUrlPath()}`,
            method: 'GET',
        }).then((res) => {
            if (res.data) {
                setTitle(res.data.title);
                setSubtitle(res.data.body);
                setAffiliateId(res.data.id);
            }
        });
    }, []);

    const validateFields = () => {
        setTitleError(false);
        let isError = false;

        if (!title) {
            setTitleError(true);
            isError = true;
        }
        if (isError) {
            return false;
        }

        return true;
    };

    const editPrompt = async () => {
        if (!validateFields()) {
            return;
        }
        setTitleError(false);

        await API({
            url: 'v1/journey/admin/prompt',
            method: 'PUT',
            data: { id: affiliateId, title, body: subtitle },
        });

        history.push('/prompt/list');
        message.success('Prompt created successfully!');
    };

    return (
        <div>
            {/* <Title level={2}>Edit Prompt</Title> */}
            <Form layout="vertical">
                <Form.Item label="Title" required>
                    <Input
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        onBlur={validateFields}
                        style={{ width: STANDART_FIELD_LENGTH }} // Adjust width as needed
                    />
                    {titleError && <div style={{ color: '#ca2d2d' }}>This field is required</div>}
                </Form.Item>

                <Form.Item label="Text">
                    <Input.TextArea
                        rows={10} // Number of rows for the textarea
                        value={subtitle}
                        onChange={(e) => setSubtitle(e.target.value)}
                    />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" onClick={editPrompt}>
                        Save
                    </Button>
                    <Button style={{ marginLeft: '10px' }} onClick={() => history.goBack()}>
                        Cancel
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default EditAffiliate;
