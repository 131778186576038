import React, { useEffect, useState } from 'react';
import { Table, Button, Typography, Modal } from 'antd';
import { useHistory } from 'react-router-dom';
import { API } from '../../../helpers/api';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

const { Column } = Table;
const { Title } = Typography;

const Articles = () => {
    const history = useHistory();
    const [articles, setArticles] = useState([]);
    const [isModalOpen, setModalOpen] = useState(false);
    const [onDeleteArticleId, setOnDeleteArticleId] = useState(null);

    const getArticles = () => {
        API({ url: 'v1/blog/articles/admin', method: 'GET' }).then((res) => {
            setArticles(res.data.articles.reverse());
        });
    };

    useEffect(() => {
        getArticles();
    }, []);

    const handleCreateArticle = () => {
        history.push('/blog/articles/create');
    };

    const handleDelete = async () => {
        await API({ url: `v1/blog/articles/${onDeleteArticleId}`, method: 'DELETE' });
        getArticles();
        setModalOpen(false);
    };

    const deleteArticle = (id) => {
        setOnDeleteArticleId(id);
        setModalOpen(true);
    };

    return (
        <div>
            <Title level={2}>Articles</Title>
            <Button
                type="primary"
                style={{ marginTop: '10px', marginLeft: '10px' }}
                onClick={handleCreateArticle}
            >
                Create New Article
            </Button>

            <Table
                dataSource={articles}
                rowKey="id"
                pagination={false}
                style={{ marginTop: '20px' }}
                bordered
            >
                <Column title="Id" dataIndex="id" key="id" />
                <Column title="Title" dataIndex="title" key="title" />

                <Column
                    title="Action"
                    key="action"
                    render={(text, record) => (
                        <>
                            <Button
                                icon={<EditOutlined />}
                                onClick={() => history.push(`/blog/articles/edit/${record.id}`)}
                                style={{ marginRight: '10px' }}
                            >
                                Edit
                            </Button>
                            <Button
                                icon={<DeleteOutlined />}
                                onClick={() => deleteArticle(record.id)}
                                danger
                            >
                                Delete
                            </Button>
                        </>
                    )}
                />
            </Table>

            <Modal
                title="Confirm Deletion"
                open={isModalOpen}
                onOk={handleDelete}
                onCancel={() => setModalOpen(false)}
            >
                <p>Are you sure you want to delete this article?</p>
            </Modal>

            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
        </div>
    );
};

export default Articles;
