import React, { useState } from 'react';
import { Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import styles from './styles.module.scss'; // Подключаем модуль стилей

const ImageUploader = ({ initialImage, onImageChange }) => {
    const [fileList, setFileList] = useState(
        initialImage
            ? [
                  {
                      uid: '-1',
                      name: 'currentImage.png',
                      status: 'done',
                      url: initialImage,
                  },
              ]
            : [],
    );

    const handleChange = ({ fileList: newFileList }) => {
        setFileList(newFileList.slice(-1));
        if (onImageChange && newFileList.length > 0) {
            onImageChange(newFileList[0]);
        }
    };

    return (
        <div className={styles.body}>
            {fileList.length > 0 ? (
                <div style={{ marginTop: 10, marginBottom: 20 }}>
                    <img
                        src={fileList[0].url || URL.createObjectURL(fileList[0].originFileObj)}
                        alt="Uploaded"
                        style={{
                            width: '100%',
                            height: 'auto',
                            maxHeight: '200px',
                            objectFit: 'contain',
                        }}
                    />
                </div>
            ) : (
                <div className={styles.emptyPhoto}>fileList</div>
            )}

            <br />

            <Upload
                fileList={fileList}
                onChange={handleChange}
                beforeUpload={() => false} // Prevent auto-upload
                accept=".png,.jpg,.jpeg,.webp"
            >
                <Button icon={<UploadOutlined />}>Загрузить новый аватар</Button>
            </Upload>
        </div>
    );
};

export default ImageUploader;
