import { useEffect, useState } from 'react';
import { Button, Typography, List } from 'antd';
import { useHistory } from 'react-router-dom';
import { API } from '../../helpers/api';
import { getLastUrlPath } from '../../helpers/getLastUrlPath';
import styles from './ChallengesItem.module.scss';

const { Title } = Typography;

const ChallengesItem = () => {
    const [challenge, setChallenge] = useState({});
    const history = useHistory();

    useEffect(() => {
        const typeEntity = getLastUrlPath(2);
        const idEntity = getLastUrlPath(1);

        const fetchChallenge = async () => {
            try {
                const { data } = await API({
                    url: `v1/challenges/admin/${typeEntity}/${idEntity}`,
                    method: 'GET',
                });
                setChallenge(data);
            } catch (e) {
                console.error(e);
            }
        };

        fetchChallenge();
    }, []);

    const acceptEntity = async () => {
        const typeEntity = getLastUrlPath(2);
        const entityId = getLastUrlPath(1);

        try {
            await API({
                url: `v1/challenges/admin/${typeEntity}/create`,
                method: 'POST',
                data: {
                    basedId: Number(entityId),
                },
            });
            history.push('/challenges/verification');
        } catch (e) {
            console.error(e);
        }
    };

    const declineEntity = async () => {
        const typeEntity = getLastUrlPath(2);
        const idEntity = getLastUrlPath(1);

        try {
            await API({
                url: `v1/challenges/admin/decline/${idEntity}`,
                method: 'PUT',
                data: { typeEntity },
            });
            history.push('/challenges/verification');
        } catch (e) {
            console.error(e);
        }
    };

    const handleUserProfile = () => {
        history.push(`/users/${challenge.user}`);
    };

    const typeEntity = getLastUrlPath(2);

    return (
        <div>
            <Title level={2}>Name: {challenge.name}</Title>
            <div className={styles.desc}>
                <strong>Description:</strong> {challenge.description || challenge.routine}
            </div>
            <div className={styles.desc}>
                <strong>Type:</strong> {typeEntity}
            </div>
            <br />
            {challenge.photo && (
                <div className={styles.img}>
                    <img src={challenge.photo} alt="Challenge" />
                </div>
            )}

            <br />
            <br />

            {challenge.triggers && challenge.triggers.length > 0 && (
                <>
                    <div>
                        <strong>TRIGGERS:</strong>
                    </div>
                    <List
                        dataSource={challenge.triggers}
                        renderItem={(item, index) => (
                            <List.Item key={index}>
                                {index + 1}. {item.name}
                            </List.Item>
                        )}
                    />
                </>
            )}

            <br />

            {challenge.rewards && challenge.rewards.length > 0 && (
                <>
                    <div>
                        <strong>REWARDS:</strong>
                    </div>
                    <List
                        dataSource={challenge.rewards}
                        renderItem={(item, index) => (
                            <List.Item key={index}>
                                {index + 1}. {item.name}
                            </List.Item>
                        )}
                    />
                </>
            )}

            <br />

            {challenge.alternatives && challenge.alternatives.length > 0 && (
                <>
                    <div>
                        <strong>ALTERNATIVES:</strong>
                    </div>
                    <List
                        dataSource={challenge.alternatives}
                        renderItem={(item, index) => (
                            <List.Item key={index}>
                                {index + 1}. {item.name}
                            </List.Item>
                        )}
                    />
                </>
            )}

            <br />

            {challenge.steps && challenge.steps.length > 0 && (
                <>
                    <div>
                        <strong>STEPS:</strong>
                    </div>
                    <List
                        dataSource={challenge.steps}
                        renderItem={(item, index) => (
                            <List.Item key={index}>
                                {index + 1}. {item.name}
                            </List.Item>
                        )}
                    />
                </>
            )}

            <br />

            {challenge.opportunities && (
                <>
                    <div>
                        <strong>OPPORTUNITIES:</strong>
                    </div>
                    <List
                        dataSource={challenge.opportunities}
                        renderItem={(item, index) => (
                            <List.Item key={index}>
                                {index + 1}. {item.name}
                            </List.Item>
                        )}
                    />
                </>
            )}

            <br />

            {challenge.tags && (
                <>
                    <div>
                        <strong>TAGS:</strong>
                    </div>
                    <List
                        dataSource={challenge.tags}
                        renderItem={(item, index) => (
                            <List.Item key={index}>
                                {index + 1}. {item.name}
                            </List.Item>
                        )}
                    />
                </>
            )}

            <br />

            {challenge.notes && challenge.notes.length > 0 && (
                <>
                    <div>
                        <strong>NOTES:</strong>
                    </div>
                    <List
                        dataSource={challenge.notes}
                        renderItem={(item, index) => (
                            <List.Item key={index}>
                                {index + 1}. {item.name}
                            </List.Item>
                        )}
                    />
                </>
            )}

            <div className={styles.btnCont}>
                <Button type="primary" onClick={acceptEntity} style={{ marginRight: '10px' }}>
                    Accept
                </Button>
                <Button type="default" onClick={declineEntity}>
                    Decline
                </Button>
                <Button type="primary" onClick={handleUserProfile}>
                    View profile
                </Button>
            </div>
        </div>
    );
};

export default ChallengesItem;
