import React, { useEffect, useState } from 'react';
import { Table, Pagination } from 'antd';
import { API } from '../../helpers/api';
import styles from './styles.module.scss';

const Users = () => {
    const [errors, setErrors] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState(0);

    const getUser = async () => {
        const { data } = await API({
            url: `v1/logging?limit=20&offset=${(currentPage - 1) * 10}`,
            method: 'GET',
        });

        setErrors(data.logs);
        setTotal(data.total); // Убедитесь, что API возвращает общее количество записей
    };

    useEffect(() => {
        getUser();
    }, [currentPage]);

    const normalizeDate = (date) => {
        return date.slice(0, 19).replace('T', ' ');
    };

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
        },
        {
            title: 'Message',
            dataIndex: 'message',
        },
        {
            title: 'Path',
            dataIndex: 'path',
        },
        {
            title: 'Status code',
            dataIndex: 'statusCode',
        },
        {
            title: 'Created',
            dataIndex: 'createdAt',
            render: (text) => normalizeDate(text),
        },
    ];

    return (
        <div className={styles.container}>
            <Table
                columns={columns}
                dataSource={errors}
                pagination={false}
            />
            <Pagination
                current={currentPage}
                total={total}
                pageSize={10}
                onChange={page => setCurrentPage(page)}
                style={{ marginTop: 20, textAlign: 'center' }}
            />
        </div>
    );
};

export default Users;