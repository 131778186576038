import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import ImageUploader from '../../components/common/ImageUploader/ImageUploader';
import { Input, Checkbox, Button, message } from 'antd';
import { API } from '../../helpers/api';

const CreateUser = () => {
    const [username, setUsername] = useState('');
    const [bio, setBio] = useState('');
    const [isPremium, setIsPremium] = useState(false);
    const [isAuthor, setIsAuthor] = useState(false);
    const [avatarFile, setAvatarFile] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const history = useHistory();

    const onImageChange = (file) => {
        setAvatarFile(file);
    };

    const createAuthorRequestBody = (id) => {
        const requestBody = new FormData();
        requestBody.append('id', id);
        if (avatarFile && avatarFile.originFileObj) {
            requestBody.append('avatar', avatarFile.originFileObj);
        }
        return requestBody;
    };

    const handleCreateUser = async () => {
        if (!username) {
            message.error('Please enter a username');
            return;
        }

        setIsSubmitting(true);

        try {
            const userResponse = await API({
                url: 'v1/users/admin/users/create',
                method: 'POST',
                data: {
                    username,
                    bio,
                    premium: isPremium,
                    isAuthor,
                },
            });

            const { id } = userResponse.data;

            message.success('User created successfully');

            if (avatarFile) {
                const photoResponse = await API({
                    url: `v1/users/admin/personal/avatar/${id}`,
                    method: 'POST',
                    data: createAuthorRequestBody(id),
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Accept: 'application/json',
                    },
                });

                if (photoResponse.status === 200) {
                    message.success('Photo uploaded successfully');
                } else {
                    message.error('Error uploading photo');
                }
            }
        } catch (error) {
            message.error(error.response?.data?.reason);
            setIsSubmitting(false);
        }

        history.push('/users');
    };

    return (
        <div>
            <h2>Create New User</h2>

            <ImageUploader onImageChange={onImageChange} />
            <br />

            <label>Username</label>
            <Input
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Enter username"
                style={{ marginBottom: 10 }}
            />

            <label>Bio</label>
            <Input.TextArea
                value={bio}
                onChange={(e) => setBio(e.target.value)}
                placeholder="Enter user bio"
                rows={4}
                style={{ marginBottom: 10 }}
            />

            <Checkbox
                checked={isPremium}
                onChange={(e) => setIsPremium(e.target.checked)}
                style={{ marginBottom: 10 }}
            >
                Premium
            </Checkbox>

            <Checkbox
                checked={isAuthor}
                onChange={(e) => setIsAuthor(e.target.checked)}
                style={{ marginBottom: 10 }}
            >
                Author
            </Checkbox>

            <br />

            <Button
                type="primary"
                onClick={handleCreateUser}
                style={{ marginTop: 20 }}
                loading={isSubmitting}
                disabled={isSubmitting}
            >
                Create
            </Button>
        </div>
    );
};

export default CreateUser;
