import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { API } from '../../../helpers/api';
import { Button, Table, Checkbox, Modal, Space, Typography } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

const { Column } = Table;
const { Title } = Typography;

const ArticleAuthors = () => {
    const [categories, setCategories] = useState([]);
    const history = useHistory();
    const [isModalOpen, setModalOpen] = useState(false);
    const [onDeleteCategoryId, setOnDeleteCategoryId] = useState(null);

    const getCategories = () => {
        API({ url: 'v1/blog/categories/admin', method: 'GET' }).then((res) => {
            setCategories(res.data.reverse());
        });
    };

    useEffect(() => {
        getCategories();
    }, []);

    const handleCreateBlog = () => {
        history.push('/blog/article-categories/create');
    };

    const modalDisagreeHandler = () => {
        setModalOpen(false);
    };

    const modalAgreeHandler = async () => {
        await API({
            url: 'v1/blog/article-categories/' + onDeleteCategoryId,
            method: 'DELETE',
        });
        getCategories();
        setModalOpen(false);
    };

    const deleteArticle = (id) => {
        setOnDeleteCategoryId(id);
        setModalOpen(true);
    };

    const toggleIsWeb = async (category) => {
        try {
            category.is_web = !category.is_web;
            await API({ url: 'v1/blog/article-categories', method: 'PUT', data: category });
            getCategories();
        } catch (e) {
            console.error(e);
        }
    };

    const toggleIsMobile = async (category) => {
        try {
            category.is_mobile = !category.is_mobile;
            await API({ url: 'v1/blog/article-categories', method: 'PUT', data: category });
            getCategories();
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <div>
            <Title level={2}>Categories</Title>
            <Button
                style={{ marginTop: '10px', marginBottom: '20px' }}
                onClick={handleCreateBlog}
                type="primary"
            >
                Create New Category
            </Button>

            <Table dataSource={categories} rowKey="id" pagination={false}>
                <Column title="Id" dataIndex="id" key="id" />
                <Column title="Title" dataIndex="title" key="title" />
                <Column
                    title="View on Web"
                    key="is_web"
                    render={(text, category) => (
                        <Checkbox
                            checked={category.is_web}
                            onChange={() => toggleIsWeb(category)}
                        />
                    )}
                />
                <Column
                    title="View on Mobile"
                    key="is_mobile"
                    render={(text, category) => (
                        <Checkbox
                            checked={category.is_mobile}
                            onChange={() => toggleIsMobile(category)}
                        />
                    )}
                />
                <Column
                    title="Action"
                    key="action"
                    render={(text, rowData) => (
                        <Space size="middle">
                            <Button
                                icon={<EditOutlined />}
                                onClick={() =>
                                    history.push(`/blog/article-categories/edit/${rowData.id}`)
                                }
                            >
                                Edit
                            </Button>
                            <Button
                                icon={<DeleteOutlined />}
                                onClick={() => deleteArticle(rowData.id)}
                                danger
                            >
                                Remove
                            </Button>
                        </Space>
                    )}
                />
            </Table>

            <Modal
                title="Confirm Deletion"
                open={isModalOpen}
                onOk={modalAgreeHandler}
                onCancel={modalDisagreeHandler}
            >
                <p>Are you sure you want to delete this category?</p>
            </Modal>

            <br />
            <br />
            <br />
            <br />
            <br />
        </div>
    );
};

export default ArticleAuthors;
