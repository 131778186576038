import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Typography } from 'antd';
import { ChallengesList } from '../../modules/Challenges';

const { Title } = Typography;

const Challenges = () => {
    const history = useHistory();

    return (
        <div>
            <div>
                <Title style={{ marginBottom: 5 }}>Challenges</Title>
                <Button
                    type="primary"
                    onClick={() => history.push('/challenges/new')}
                    icon={<span className="anticon anticon-plus-circle" />}
                >
                    Add Challenge
                </Button>
            </div>

            <br />

            <ChallengesList />
        </div>
    );
};

export { Challenges };
