export const normalizeDate = (date) => {
    if (!date) return;
    return date.replace('T', ' ').replace('Z', ' ').slice(0, -8);
};

export const getAppName = () => {
    const { hostname } = window.location;
    if (hostname === 'admin.confyday.com' || hostname === 'localhost') {
        return 'Confyday'.toUpperCase();
    }

    if (hostname === 'admin.wisme.app') {
        return 'Wisme'.toUpperCase();
    }
};
