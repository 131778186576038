import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Input, Button, message } from 'antd';
import { API } from '../../../helpers/api';

const CreateTemplate = () => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const history = useHistory();

    const handleCreateTemplate = async () => {
        if (!name) {
            message.error('Title is required');
            return;
        }

        await API({
            url: 'v1/journal/admin/templates',
            method: 'POST',
            data: {
                title: name,
                description,
            },
        });

        message.success('Template created successfully');
        history.push('/journal/list');
    };

    return (
        <div>
            <h2>Create Template</h2>
            <Form layout="vertical">
                <Form.Item label="Title" required>
                    <Input value={name} onChange={(e) => setName(e.target.value)} />
                </Form.Item>
                <Form.Item label="Description">
                    <Input.TextArea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" onClick={handleCreateTemplate}>
                        Create
                    </Button>
                    <Button style={{ marginLeft: '8px' }} onClick={() => history.goBack()}>
                        Cancel
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default CreateTemplate;
