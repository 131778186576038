import React from 'react';
import { Header as HeaderBox, Navbar, Nav, Icon } from 'rsuite';
import styles from './Header.module.scss';
import { getAppName } from '../../../helpers/common';

const Header = () => {
    const logOut = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('expiresIn');
        window.location.reload();
    };

    return (
        <HeaderBox className={styles.Header}>
            <Navbar className={styles.navbar}>
                <Navbar.Header>
                    <Nav>
                        <Nav.Item href={'/'} className={styles.logo} icon={<Icon icon="home" />}>
                            {getAppName()}
                        </Nav.Item>
                    </Nav>
                </Navbar.Header>
                <Navbar.Body>
                    <Nav pullRight>
                        <Nav.Item onClick={logOut} icon={<Icon icon="cog" />}>
                            Log out
                        </Nav.Item>
                    </Nav>
                </Navbar.Body>
            </Navbar>
        </HeaderBox>
    );
};

export default Header;
