import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Form,
    FormGroup,
    ControlLabel,
    ButtonToolbar,
    Button,
    Uploader,
    Icon,
    Table,
} from 'rsuite';
import { API } from '../../helpers/api';
import { PERSONAL_NOTIFICATION_TYPES, VIOLATION_NOTICE } from '../../constants';

const { Column, Cell, HeaderCell } = Table;

const EditPost = () => {
    const [body, setBody] = useState('');
    const [postId, setPostId] = useState(0);
    const [previewPictures, setPreviewPictures] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [nameCategory, setNameCategory] = useState('');
    const [categories, setCategories] = useState([]);
    const [userId, setUserId] = useState(0);
    const [userIdPost, setUserIdPost] = useState(0);
    const history = useHistory();
    const [comments, setComments] = useState([]);
    const [onDeleteLinks, setOnDeleteLinks] = useState([]);
    const [adminAuthors, setAdminAuthors] = useState([]);
    const [pickedUserAuthor, setPickedUserAuthor] = useState(null);

    const getCategories = () => {
        API({ url: 'v1/blog/categories/admin', method: 'GET' }).then((res) => {
            const _categories = res.data.map((item) => item.alias);
            _categories.push('');
            setCategories(_categories);
        });
    };

    const getUserAuthors = () => {
        API({ url: 'v1/users/admin/authors', method: 'GET' }).then((res) => {
            res.data.users.unshift('');
            console.log(res.data.users);
            setAdminAuthors(res.data.users);
        });
    };

    const deleteComment = async (commentId) => {
        try {
            await API({
                url: `v1/community/admin/comment/${commentId}`,
                method: 'DELETE',
            });
            getPost(postId);
        } catch (e) {
            console.error(e);
        }
    };

    const sendNotice = async (userId) => {
        try {
            await API({
                url: 'v1/users/notice/report',
                method: 'POST',
                data: {
                    user_id: userId,
                    message: VIOLATION_NOTICE,
                    type: PERSONAL_NOTIFICATION_TYPES.violationNotice,
                },
            });
            alert('Notice sent!');
        } catch (e) {
            console.error(e);
        }
    };

    const blockAccount = async (userId) => {
        try {
            await API({
                url: 'v1/users/account/block',
                method: 'POST',
                data: {
                    userId,
                },
            });
            alert('Account blocked');
        } catch (e) {
            console.error(e);
        }
    };

    const getPost = async (postId) => {
        try {
            const { data } = await API({
                url: `v1/community/admin/posts/${postId}`,
                method: 'GET',
            });
            setBody(data.body);
            setUserId(data.user_data.id);
            console.log(data.user_id);
            setUserIdPost(data.user_id);

            if (data.image_links?.length) {
                const imageList = data.image_links.map((link, index) => ({
                    name: `${index + 1}.png`,
                    fileKey: index + 1,
                    url: link,
                }));

                setFileList(imageList);
            }

            setPickedUserAuthor(data.user_data.name);

            if (data.category_name) {
                setNameCategory(data.category_name);
            }

            if (data.comments && data.comments.length) {
                setComments(data.comments);
            } else {
                setComments([]);
            }
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        const urlArr = history.location.pathname.split('/');
        const postId = urlArr[urlArr.length - 1];

        setPostId(postId);

        getPost(postId);
        getCategories();
        getUserAuthors();
    }, [history.location.pathname]);

    const createArticleRequestBody = (post) => {
        const requestBody = new FormData();
        requestBody.append('community', post.community);
        return requestBody;
    };

    const updatePost = async () => {
        let userAuthor = null;
        if (!pickedUserAuthor) {
            userAuthor = adminAuthors.find((i) => i.id === 5);
        } else {
            userAuthor = adminAuthors.find((i) => i.nickName === pickedUserAuthor);
        }

        await API({
            url: 'v1/community/admin/posts',
            method: 'PUT',
            data: {
                id: Number(postId),
                body,
                category_name: nameCategory,
                user_data: {
                    id: userAuthor.id,
                    name: userAuthor.nickName,
                    bio: userAuthor.bio,
                    image_link: userAuthor.photoUrl
                },
            },
        });

        if (previewPictures?.length) {
            // Создаем новый объект FormData
            const formData = new FormData();

            // Добавляем каждую картинку в formData с ключом 'images'
            previewPictures.forEach((image) => {
                if (image?.blobFile) {
                    formData.append('community', image.blobFile); // используем ключ 'images' для соответствия FilesInterceptor
                }
            });

            // Добавляем id и userId в formData
            formData.append('id', postId);
            formData.append('userId', userIdPost);

            // Отправляем один запрос с formData, содержащим все изображения
            await API({
                url: 'v1/community/admin/upload-images', // URL нового эндпоинта
                method: 'POST',
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Accept: 'application/json',
                    id: postId,
                    userId: userIdPost,
                },
            });
        }

        if (onDeleteLinks?.length) {
            for (const link of onDeleteLinks) {
                const linkUuid = link.split('/')[link.split('/').length - 1];

                console.log(userIdPost);

                if (linkUuid) {
                    await API({
                        url: `v1/community/admin/posts/image/${postId}/${linkUuid}`,
                        method: 'DELETE',
                        data: {
                            userId: userIdPost,
                        },
                    });
                }
            }
        }

        history.push('/community/posts');
    };

    const handleSelectPicture = (files) => {
        if (files.length > fileList.length) {
            if (files?.length) {
                setTimeout(() => {
                    setFileList(files);
                }, 500);

                return setPreviewPictures(files);
            }
            setPreviewPictures([]);
        }

        setFileList(fileList);
    };

    const removePickImage = (item) => {
        console.log('removePickImage');
        let _fileList = [...fileList.filter((image) => image.fileKey !== item.fileKey)];
        let _onDeleteLinks = [...onDeleteLinks, item.url];

        console.log(_onDeleteLinks);

        setTimeout(() => {
            setFileList(_fileList);
        }, 500);
        setOnDeleteLinks(_onDeleteLinks);
    };

    return (
        <div style={{ position: 'relative' }}>
            <h2>Edit Post</h2>
            <Form>
                {!fileList.length ? (
                    <Uploader
                        onChange={handleSelectPicture}
                        listType="picture"
                        accept=".png,.jpg,.jpeg,.webp"
                        autoUpload={false}
                        onRemove={removePickImage}
                    >
                        <button>
                            <Icon icon="camera-retro" size="lg" />
                        </button>
                    </Uploader>
                ) : (
                    <Uploader
                        onChange={handleSelectPicture}
                        listType="picture"
                        accept=".png,.jpg,.jpeg,.webp"
                        autoUpload={false}
                        fileList={fileList}
                        onRemove={removePickImage}
                        multiple
                    >
                        <button>
                            <Icon icon="camera-retro" size="lg" />
                        </button>
                    </Uploader>
                )}

                <br />
                <br />

                <select value={nameCategory} onChange={(e) => setNameCategory(e.target.value)}>
                    {categories.map((item, index) => {
                        return <option key={index}>{item}</option>;
                    })}
                </select>

                <br />
                <br />

                <p>Authors</p>
                <select
                    value={pickedUserAuthor}
                    onChange={(e) => setPickedUserAuthor(e.target.value)}
                >
                    {adminAuthors.map((item) => (
                        <option key={item.id} value={item.nickName}>
                            {item.nickName}
                        </option>
                    ))}
                </select>

                <br />
                <br />

                <ControlLabel>
                    <textarea
                        onChange={(e) => setBody(e.target.value)}
                        value={body}
                        rows={25}
                        cols={70}
                    />
                </ControlLabel>

                <br />
                <br />
                <FormGroup>
                    <ButtonToolbar>
                        <Button onClick={updatePost} appearance="primary">
                            Save
                        </Button>
                        <Button appearance="default" onClick={() => history.goBack()}>
                            Cancel
                        </Button>
                    </ButtonToolbar>
                </FormGroup>
                <br />
                <br />
            </Form>

            <div>
                <h2>Comments</h2>

                <Table style={{ width: 1500 }} data={comments} autoHeight rowHeight={70}>
                    <Column width={50} verticalAlign={'middle'}>
                        <HeaderCell>Id</HeaderCell>
                        <Cell dataKey={'id'} />
                    </Column>

                    <Column width={150} verticalAlign={'middle'}>
                        <HeaderCell>Sender's name</HeaderCell>
                        <Cell>{(data) => <div>{data.user_data.name}</div>}</Cell>
                    </Column>

                    <Column width={500} verticalAlign={'middle'}>
                        <HeaderCell>Message</HeaderCell>
                        <Cell dataKey={'body'} />
                    </Column>

                    <Column width={150} verticalAlign={'middle'}>
                        <HeaderCell>Notice Action</HeaderCell>
                        <Cell>
                            {(data) => (
                                <Button
                                    onClick={() => sendNotice(data.user_data.id)}
                                    appearance="primary"
                                >
                                    Send Notice
                                </Button>
                            )}
                        </Cell>
                    </Column>
                    <Column width={150} verticalAlign={'middle'}>
                        <HeaderCell>Block account Action</HeaderCell>
                        <Cell>
                            {(data) => (
                                <Button
                                    onClick={() => blockAccount(data.user_data.id)}
                                    color={data.user_is_block ? 'green' : 'red'}
                                    appearance="primary"
                                >
                                    {data.user_is_block ? 'Unblock account' : 'Block account'}
                                </Button>
                            )}
                        </Cell>
                    </Column>
                    <Column width={200} verticalAlign={'middle'}>
                        <HeaderCell>Delete comment</HeaderCell>
                        <Cell>
                            {(data) => (
                                <Button
                                    onClick={() => deleteComment(data.id)}
                                    color={data.user_is_block ? 'green' : 'red'}
                                    appearance="primary"
                                >
                                    Delete comment
                                </Button>
                            )}
                        </Cell>
                    </Column>
                </Table>
            </div>
        </div>
    );
};

export default EditPost;
