import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { slugify } from 'transliteration';
import { Select } from 'antd';
import {
    Form,
    FormGroup,
    FormControl,
    ControlLabel,
    ButtonToolbar,
    Button,
    FlexboxGrid,
    Uploader,
    Icon,
} from 'rsuite';
import TextEditor from '../../../components/common/TextEditor/TextEditor';
import BoxUploader from '../../../components/common/BoxUploader/BoxUploader';
import { API } from '../../../helpers/api';
import styles from './EditArticle.module.scss';

const { Option } = Select;

const EditArticle = () => {
    const [categories, setCategories] = useState([]);
    const [article, setArticle] = useState([]);

    const [title, setTitle] = useState('');
    const [subtitle, setSubtitle] = useState('');
    const [description, setDescription] = useState('');
    const [detailText, setDetailText] = useState('');

    const [categoryPicked, pickCategory] = useState('');
    const [categoryError, setCategoryError] = useState(false);

    const [author, pickAuthor] = useState(false);

    const [isMain, setIsMain] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [previewPicture, setPreviewPicture] = useState('');
    const [fileList, setFileList] = useState([]);
    const [authors, setAuthors] = useState([]);

    const history = useHistory();

    useEffect(() => {
        const fetchData = async () => {
            const articleAuthors = await API({ url: 'v1/users/admin/authors', method: 'GET' });

            articleAuthors.data.users.forEach((item) => {
                item.label = item.nickName;
                item.value = item.id;
            });

            const { data: categoriesData } = await API({
                url: 'v1/blog/categories/admin',
                method: 'GET',
            });

            categoriesData.forEach((item) => {
                item.label = item.title;
                item.value = item.id;
            });

            const urlArr = history.location.pathname.split('/');
            const lastChunkUrl = urlArr[urlArr.length - 1];

            const article = await API({ url: `v1/blog/articles/${lastChunkUrl}`, method: 'GET' });

            if (article.data) {
                setTitle(article.data.title);
                setSubtitle(article.data.subtitle);
                setDescription(article.data.description);
                setDetailText(article.data.detailText);
                setIsMain(article.data.isMain);
                setIsActive(article.data.isActive);

                if (article.data.author_id) {
                    const [pickedAuthor] = articleAuthors.data.users.filter(
                        (author) => author.id === article.data.author_id,
                    );

                    if (pickedAuthor) {
                        articleAuthors.data.users.unshift(pickedAuthor);
                    }
                } else {
                    articleAuthors.data.users.unshift({ label: '', id: 0 });
                }

                if (article.data.previewPicture) {
                    setFileList([
                        {
                            name: '1png',
                            fileKey: 1,
                            url: article.data.previewPicture.path,
                        },
                    ]);
                    setPreviewPicture('exist');
                }

                setArticle(article.data);

                setAuthors(articleAuthors.data.users);
                setCategories(categoriesData);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (categories.length) {
            console.log(article.category.title);
            pickCategoryFunc(article.category.title);
        }
    }, [categories.length]);

    useEffect(() => {
        if (authors.length) {
            const author = authors.find((a) => a.id === article.author_id);
            pickAuthor(author);
        }
    }, [authors.length]);

    const removePickImage = () => {
        setFileList([]);
    };

    const validateFields = () => {
        if (!categoryPicked) {
            setCategoryError(true);
            return false;
        }

        return true;
    };

    const createArticleRequestBody = (article) => {
        const requestBody = new FormData();

        if (article.id) {
            requestBody.append('id', article.id);
        }

        requestBody.append('title', article.title);
        requestBody.append('subtitle', article.subtitle);
        requestBody.append('description', article.description);
        requestBody.append('detailText', article.detailText);
        requestBody.append('categoryId', article.categoryId);
        requestBody.append('isMain', article.isMain);
        requestBody.append('isActive', article.isActive);
        requestBody.append('alias', article.alias);
        requestBody.append('author', article.author);
        requestBody.append('author_id', article.authorId);

        if (article.previewPicture) {
            requestBody.append('previewPicture', article.previewPicture);
        } else {
            requestBody.append('previewPicture', JSON.stringify({ path: '' }));
        }

        if (article.previewPictureId) {
            requestBody.append('previewPictureId', article.previewPictureId);
        }

        return requestBody;
    };

    const editArticle = async () => {
        if (!validateFields()) {
            return;
        }
        setCategoryError(false);

        const data = {
            title,
            subtitle,
            description,
            alias: slugify(title),
            isMain,
            isActive,
            detailText,
            categoryId: categoryPicked.id,
            authorId: author.id,
        };

        if (previewPicture) {
            data['previewPicture'] = previewPicture;
        }

        const urlArr = history.location.pathname.split('/');
        const lastChunkUrl = urlArr[urlArr.length - 1];
        data.id = lastChunkUrl;

        await API({
            url: 'v1/blog/articles',
            method: 'PUT',
            data: createArticleRequestBody(data),
            headers: {
                'Content-Type': 'multipart/form-data',
                Accept: 'application/json',
                type: 'formData',
            },
        });

        alert('Updated');
    };

    const handleSelectPicture = (files) => {
        if (files.length > 0) {
            return setPreviewPicture(files[0].blobFile);
        }
        setPreviewPicture(null);
    };

    const pickCategoryFunc = (title) => {
        setCategoryError(false);
        const [pickedCategory] = categories.filter((category) => category.title === title);
        if (pickedCategory) {
            pickCategory(pickedCategory);
        }
    };

    const pickAuthorFunc = (name) => {
        const [pickedAuthor] = authors.filter((author) => author.nickName === name);

        if (pickedAuthor) {
            pickAuthor(pickedAuthor);
        }
    };

    return (
        <div className={styles.articleBox}>
            <BoxUploader />

            <h2>Update Article</h2>
            <br />
            <Form>
                <FormGroup>
                    <ControlLabel>Title</ControlLabel>
                    <FormControl value={title} onChange={(text) => setTitle(text)} name="title" />
                </FormGroup>
                <FormGroup>
                    <ControlLabel>Subtitle</ControlLabel>
                    <FormControl
                        value={subtitle}
                        onChange={(text) => setSubtitle(text)}
                        name="subtitle"
                    />
                </FormGroup>
                <FormGroup>
                    <ControlLabel>Description</ControlLabel>
                    <FormControl
                        value={description}
                        onChange={(text) => setDescription(text)}
                        name="description"
                    />
                </FormGroup>

                <div>
                    <ControlLabel>Categories</ControlLabel>
                </div>
                <Select
                    value={categoryPicked.title}
                    onChange={pickCategoryFunc}
                    placeholder="Select a category"
                    dropdownStyle={{ width: 300 }}
                >
                    {categories.map((item, index) => (
                        <Option key={index} value={item.title}>
                            {item.title}
                        </Option>
                    ))}
                </Select>

                <br />
                <br />

                <div>
                    <ControlLabel>Author</ControlLabel>
                </div>
                <Select
                    value={author?.nickName}
                    onChange={pickAuthorFunc}
                    placeholder="Select a author"
                    dropdownStyle={{ width: 300 }}
                >
                    {authors.map((item, index) => (
                        <Option key={index} value={item.label}>
                            {item.label}
                        </Option>
                    ))}
                </Select>

                <br />
                <br />

                <FlexboxGrid>
                    <input
                        className={styles.checkBox}
                        checked={isActive}
                        onClick={() => setIsActive(!isActive)}
                        type="checkbox"
                    />
                    <div>Active</div>
                </FlexboxGrid>

                {!fileList.length ? (
                    <Uploader
                        onChange={handleSelectPicture}
                        listType="picture"
                        accept=".png,.jpg,.jpeg,.webp"
                        autoUpload={false}
                        onRemove={removePickImage}
                    >
                        <button>
                            <Icon icon="camera-retro" size="lg" />
                        </button>
                    </Uploader>
                ) : (
                    <Uploader
                        onChange={handleSelectPicture}
                        listType="picture"
                        accept=".png,.jpg,.jpeg,.webp"
                        autoUpload={false}
                        fileList={fileList}
                        onRemove={removePickImage}
                    >
                        <button>
                            <Icon icon="camera-retro" size="lg" />
                        </button>
                    </Uploader>
                )}

                <br />
                <br />

                <ControlLabel>Text</ControlLabel>
                <TextEditor text={detailText} onChange={setDetailText} />
                <br />
                <FormGroup>
                    <ButtonToolbar>
                        <Button onClick={editArticle} appearance="primary">
                            Update
                        </Button>
                        <Button appearance="default" onClick={() => history.goBack()}>
                            Cancel
                        </Button>
                    </ButtonToolbar>
                </FormGroup>
                <br />
                <br />
            </Form>
        </div>
    );
};

export default EditArticle;
