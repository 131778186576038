import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Table, Modal, Space } from 'antd';
import { API } from '../../../helpers/api';
import { normalizeDate } from '../../../helpers/common';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

const Karmas = () => {
    const history = useHistory();
    const [items, setItems] = useState([]);
    const [isModalOpen, setModalOpen] = useState(false);
    const [onDeleteAffiliateId, setOnDeleteAffiliateId] = useState(null);

    const getAffiliate = () => {
        API({ url: 'v1/journey/karma/rules', method: 'GET' }).then((res) => {
            setItems(res.data.rules.reverse());
        });
    };

    useEffect(() => {
        getAffiliate();
    }, []);

    const handleCreateAffiliate = () => {
        history.push('/karma/create');
    };

    const modalDisagreeHandler = () => {
        setModalOpen(false);
    };

    const modalAgreeHandler = () => {
        API({ url: 'v1/journey/karma/rules/' + onDeleteAffiliateId, method: 'DELETE' }).then(() => {
            getAffiliate();
        });
        setModalOpen(false);
    };

    const deleteArticle = (id) => {
        setOnDeleteAffiliateId(id);
        setModalOpen(true);
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'rule_name',
            width: 150,
        },
        {
            title: 'Action',
            render: (text, record) => (
                <Space>
                    <Button type="primary" onClick={() => history.push(`edit/${record.id}`)}>
                        Edit
                    </Button>
                    <Button type="primary" danger onClick={() => deleteArticle(record.id)}>
                        Remove
                    </Button>
                </Space>
            ),
            width: 80,
        },
    ];

    return (
        <div>
            <h2>Karma Rules</h2>
            <Button type="primary" onClick={handleCreateAffiliate} style={{ marginBottom: '16px' }}>
                Create
            </Button>
            <Table
                columns={columns}
                dataSource={items}
                rowKey="id"
                pagination={false}
                scroll={{ x: 1200 }}
            />
            <Modal
                title="Confirm Deletion"
                visible={isModalOpen}
                onOk={modalAgreeHandler}
                onCancel={modalDisagreeHandler}
            >
                <p>Are you sure you want to delete this rule?</p>
            </Modal>
        </div>
    );
};

export default Karmas;
