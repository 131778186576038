import React from 'react';
import { Card, Col, Row, Statistic, Divider } from 'antd';
import {
    ReadOutlined,
    FlagOutlined,
    CheckCircleOutlined,
    SmileOutlined,
    TeamOutlined,
    BookOutlined,
    CalendarOutlined,
    BulbOutlined,
} from '@ant-design/icons';

const Dashboard = () => {
    // Test data
    const stats = [
        {
            title: 'Blog',
            value: 120,
            icon: <ReadOutlined />,
            description: 'Articles',
        },
        {
            title: 'Challenges',
            value: 35,
            icon: <FlagOutlined />,
            description: 'Current challenges',
        },
        {
            title: 'Goals',
            value: 15,
            icon: <CheckCircleOutlined />,
            description: 'Achieved goals',
        },
        {
            title: 'Habits',
            value: 42,
            icon: <BulbOutlined />,
            description: 'Working habits',
        },
        {
            title: 'Community',
            value: 200,
            icon: <TeamOutlined />,
            description: 'Community members',
        },
        {
            title: 'Journal',
            value: 50,
            icon: <BookOutlined />,
            description: 'Journal entries',
        },
        {
            title: 'Mood',
            value: 4.5,
            icon: <SmileOutlined />,
            description: 'Average mood (out of 5)',
        },
        {
            title: 'Planner',
            value: 7,
            icon: <CalendarOutlined />,
            description: 'Scheduled tasks',
        },
    ];

    return (
        <div style={{ padding: '20px' }}>
            <h2>Main Metrics</h2>
            <Row gutter={16}>
                {stats.map((stat, index) => (
                    <Col span={6} key={index}>
                        <Card>
                            <Statistic
                                title={stat.title}
                                value={stat.value}
                                prefix={stat.icon}
                                suffix={stat.description}
                            />
                        </Card>
                    </Col>
                ))}
            </Row>

            <br />
            <br />

            <h3 style={{ fontSize: '34px' }}>Stay Informed and Analyze</h3>
            <Divider />
            <p style={{ fontSize: '24px', width: 600 }}>
                On this dashboard, you can pull fresh articles and research from leading resources
                in the fields of psychology and self-development, including PubMed, PsyArXiv, Open
                Science Framework, and others.
            </p>
            <Divider />
            <p style={{ fontSize: '24px', width: 600 }}>
                With real-time analysis powered by GPT, you can draw insights and sketch ideas based
                on the latest findings. This feature allows you to add new algorithms through text
                and engage in continuous learning and development.
            </p>
        </div>
    );
};

export default Dashboard;
