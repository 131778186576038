import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { slugify } from 'transliteration';
import {
    Form,
    FormGroup,
    FormControl,
    ControlLabel,
    ButtonToolbar,
    Button,
    FlexboxGrid,
    Uploader,
    Icon,
    InputPicker,
} from 'rsuite';
import TextEditor from '../../../components/common/TextEditor/TextEditor';
import BoxUploader from '../../../components/common/BoxUploader/BoxUploader';
import { API } from '../../../helpers/api';
import styles from './styles.module.scss';

const CreateArticle = () => {
    const [categories, setCategories] = useState([]);
    const [authors, setAuthors] = useState([]);

    const [title, setTitle] = useState('');
    const [subtitle, setSubtitle] = useState('');
    const [description, setDescription] = useState('');
    const [detailText, setDetailText] = useState('');

    const [categoryId, pickCategory] = useState('');
    const [categoryError, setCategoryError] = useState(false);

    const [authorId, pickAuthor] = useState(false);
    const [authorError, setAuthorError] = useState(false);

    const [isMain, setIsMain] = useState(false);
    const [isActive, setIsActive] = useState(true);
    const [previewPicture, setPreviewPicture] = useState('');

    const history = useHistory();

    useEffect(() => {
        API({ url: 'v1/blog/categories/admin', method: 'GET' }).then((res) => {
            res.data.forEach((item) => {
                item.label = item.title;
                item.value = item.id;
            });

            setCategories(res.data);
        });

        API({ url: 'v1/users/admin/authors', method: 'GET' }).then((res) => {
            res.data.users.forEach((item) => {
                item.label = item.nickName;
                item.value = item.id;
            });

            setAuthors(res.data.users);
        });
        setIsActive(true);
    }, []);

    const validateFields = () => {
        if (!categoryId) {
            setCategoryError(true);
            return false;
        }

        if (!authorId) {
            setAuthorError(true);
            return false;
        }

        return true;
    };

    const createArticleRequestBody = (article) => {
        const requestBody = new FormData();

        if (article.id) {
            requestBody.append('id', article.id);
        }

        requestBody.append('title', article.title);
        requestBody.append('subtitle', article.subtitle);
        requestBody.append('description', article.description);
        requestBody.append('detailText', article.detailText);
        requestBody.append('previewPicture', article.previewPicture);
        requestBody.append('previewPictureId', article.previewPictureId);
        requestBody.append('categoryId', article.categoryId);
        requestBody.append('author_id', article.authorId.id);
        requestBody.append('isMain', article.isMain);
        requestBody.append('isActive', article.isActive);
        requestBody.append('alias', article.alias);

        return requestBody;
    };

    const createArticle = async () => {
        if (!validateFields()) {
            return;
        }
        setCategoryError(false);
        setAuthorError(false);

        const data = {
            title,
            subtitle,
            description,
            alias: slugify(title),
            isMain,
            isActive,
            detailText,
            categoryId,
            authorId,
        };

        if (previewPicture) {
            data['previewPicture'] = previewPicture;
        }

        await API({
            url: 'v1/blog/articles',
            method: 'POST',
            data: createArticleRequestBody(data),
            headers: {
                'Content-Type': 'multipart/form-data',
                Accept: 'application/json',
                type: 'formData',
            },
        });

        history.push('/blog/articles');
    };

    const handleSelectPicture = (files) => {
        if (files.length > 0) {
            return setPreviewPicture(files[0].blobFile);
        }
        setPreviewPicture(null);
    };

    const pickAuthorFunc = (id) => {
        setAuthorError(false);
        const pickedAuthor = authors.filter((author) => author.id === id);

        if (pickedAuthor[0]) {
            pickAuthor(pickedAuthor[0]);
        }
    };

    return (
        <div style={{ position: 'relative' }}>
            <BoxUploader />

            <h2>Create Article</h2>
            <Form>
                <FormGroup>
                    <ControlLabel>Title</ControlLabel>
                    <FormControl value={title} onChange={(text) => setTitle(text)} name="title" />
                </FormGroup>
                <FormGroup>
                    <ControlLabel>Subtitle</ControlLabel>
                    <FormControl
                        value={subtitle}
                        onChange={(text) => setSubtitle(text)}
                        name="subtitle"
                    />
                </FormGroup>
                <FormGroup>
                    <ControlLabel>Description</ControlLabel>
                    <FormControl
                        value={description}
                        onChange={(text) => setDescription(text)}
                        name="description"
                    />
                </FormGroup>

                <FormGroup>
                    <ControlLabel>Category</ControlLabel>
                    <InputPicker
                        onChange={(id) => {
                            pickCategory(id);
                            setCategoryError(false);
                        }}
                        data={categories}
                        style={{ width: 224 }}
                    />
                    {categoryError && (
                        <div style={{ color: '#ca2d2d' }}>This field is required</div>
                    )}
                </FormGroup>

                <FormGroup>
                    <ControlLabel>Author</ControlLabel>

                    <InputPicker
                        onChange={(id) => {
                            pickAuthorFunc(id);
                            setAuthorError(false);
                        }}
                        data={authors}
                        style={{ width: 224 }}
                    />

                    {authorError && <div style={{ color: '#ca2d2d' }}>This field is required</div>}
                </FormGroup>
                <br />
                <br />

                <FlexboxGrid>
                    <input
                        className={styles.checkBox}
                        checked={isActive}
                        onClick={() => setIsActive(!isActive)}
                        type="checkbox"
                    />
                    <div>Active</div>
                </FlexboxGrid>

                <Uploader
                    onChange={handleSelectPicture}
                    listType="picture"
                    accept=".png,.jpg,.jpeg,.webp"
                    autoUpload={false}
                >
                    <button>
                        <Icon icon="camera-retro" size="lg" />
                    </button>
                </Uploader>

                <br />
                <br />

                <ControlLabel>Text</ControlLabel>
                <TextEditor text={detailText} onChange={setDetailText} />
                <br />
                <FormGroup>
                    <ButtonToolbar>
                        <Button onClick={createArticle} appearance="primary">
                            Create
                        </Button>
                        <Button appearance="default" onClick={() => history.goBack()}>
                            Cancel
                        </Button>
                    </ButtonToolbar>
                </FormGroup>
                <br />
                <br />
            </Form>
        </div>
    );
};

export default CreateArticle;
