import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Table, Modal } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { API } from '../../../helpers/api';

const Templates = () => {
    const history = useHistory();
    const [templates, setTemplates] = useState([]);
    const [isModalOpen, setModalOpen] = useState(false);
    const [onDeleteTemplateId, setOnDeleteTemplateId] = useState(null);

    const getTemplates = () => {
        API({ url: 'v1/journal/admin/templates', method: 'GET' }).then((res) => {
            setTemplates(res.data.items);
        });
    };

    useEffect(() => {
        getTemplates();
    }, []);

    const handleCreateTemplate = () => {
        history.push('/journal/create');
    };

    const handleModalCancel = () => {
        setModalOpen(false);
    };

    const handleModalConfirm = () => {
        API({ url: 'v1/journal/admin/templates/' + onDeleteTemplateId, method: 'DELETE' }).then(
            () => {
                getTemplates();
            },
        );
        setModalOpen(false);
    };

    const deleteTemplate = (id) => {
        setOnDeleteTemplateId(id);
        setModalOpen(true);
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'title',
            key: 'title',
            width: '200px',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            width: '400px',
        },
        {
            title: 'Action',
            key: 'action',
            fixed: 'right',
            render: (text, record) => (
                <>
                    <Button
                        onClick={() => history.push(`edit/${record.id}`)}
                        icon={<EditOutlined />}
                        type="primary"
                        size="small"
                        style={{ marginRight: 8 }}
                    >
                        Edit
                    </Button>
                    <Button
                        onClick={() => deleteTemplate(record.id)}
                        icon={<DeleteOutlined />}
                        size="small"
                        danger
                    >
                        Remove
                    </Button>
                </>
            ),
        },
    ];

    return (
        <div>
            <h2>Templates</h2>
            <Button type="primary" onClick={handleCreateTemplate} style={{ marginBottom: 16 }}>
                Create template
            </Button>
            <Table
                columns={columns}
                dataSource={templates}
                rowKey="id"
                pagination={false}
                scroll={{ x: 1200 }}
            />
            <Modal
                title="Confirm Deletion"
                visible={isModalOpen}
                onOk={handleModalConfirm}
                onCancel={handleModalCancel}
            >
                <p>Are you sure you want to delete this template?</p>
            </Modal>
        </div>
    );
};

export default Templates;
