import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Table, Modal, Typography, Space, message } from 'antd';
import { API } from '../../../helpers/api';
import { normalizeDate } from '../../../helpers/common';

const { Title } = Typography;

const Prompts = () => {
    const history = useHistory();
    const [affiliates, setAffiliate] = useState([]);
    const [isModalOpen, setModalOpen] = useState(false);
    const [onDeleteAffiliateId, setOnDeleteAffiliateId] = useState(null);

    const getAffiliate = () => {
        API({ url: 'v1/journey/admin/prompt', method: 'GET' }).then((res) => {
            setAffiliate(res.data.items.reverse());
        });
    };

    useEffect(() => {
        getAffiliate();
    }, []);

    const handleCreateAffiliate = () => {
        history.push('/prompt/create');
    };

    const modalDisagreeHandler = () => {
        setModalOpen(false);
    };

    const modalAgreeHandler = async () => {
        await API({ url: `v1/journey/admin/prompt/${onDeleteAffiliateId}`, method: 'DELETE' });
        getAffiliate();
        setModalOpen(false);
        message.success('Prompt deleted');
    };

    const deleteArticle = (id) => {
        setOnDeleteAffiliateId(id);
        setModalOpen(true);
    };

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            width: 50,
        },
        {
            title: 'Title',
            dataIndex: 'title',
            width: 350,
        },
        {
            title: 'Text',
            dataIndex: 'body',
            width: 700,
        },
        {
            title: 'Created',
            dataIndex: 'created_at',
            render: (date) => <span>{date.split('T')[0]}</span>,
            width: 150,
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Button type="primary" onClick={() => history.push(`edit/${record.id}`)}>
                        Edit
                    </Button>
                    <Button type="primary" danger onClick={() => deleteArticle(record.id)}>
                        Remove
                    </Button>
                </Space>
            ),
            width: 100,
        },
    ];

    return (
        <div>
            <Title level={2}>Prompts</Title>
            <Button type="primary" onClick={handleCreateAffiliate} style={{ marginBottom: '16px' }}>
                Create Prompt
            </Button>
            <Table
                dataSource={affiliates}
                columns={columns}
                rowKey="id"
                pagination={false}
                style={{ width: '100%' }}
            />
            <Modal
                title="Confirm Deletion"
                visible={isModalOpen}
                onOk={modalAgreeHandler}
                onCancel={modalDisagreeHandler}
                okText="Yes"
                cancelText="No"
            >
                <p>Are you sure you want to delete this prompt?</p>
            </Modal>

            <br />
            <br />
            <br />
            <br />
            <br />
        </div>
    );
};

export default Prompts;
