import { useEffect, useState } from 'react';
import { Table, Button, Select, Typography, Space, Modal } from 'antd';
import { useHistory } from 'react-router-dom';
import { API } from '../../helpers/api';
import styles from './styles.module.scss';
import { PlusCircleOutlined } from '@ant-design/icons';

const { Column } = Table;
const { Title } = Typography;

const Posts = () => {
    const history = useHistory();
    const [posts, setPosts] = useState([]);
    const [isModalOpen, setModalOpen] = useState(false);
    const [postId, setPostId] = useState(null);
    const [categories, setCategories] = useState([]);
    const [adminAuthors, setAdminAuthors] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPosts, setTotalPosts] = useState(0);
    const postsPerPage = 6;

    const getCategories = async () => {
        const res = await API({ url: 'v1/blog/categories/admin', method: 'GET' });
        const _categories = res.data.map((item) => item.alias);
        _categories.push('');
        setCategories(_categories);
    };

    const getUserAuthors = async () => {
        const res = await API({ url: 'v1/users/admin/authors', method: 'GET' });
        setAdminAuthors(res.data.users);
    };

    const getPosts = async () => {
        const { data } = await API({
            url: `v1/community/admin/posts?limit=${postsPerPage}&offset=${
                (currentPage - 1) * postsPerPage
            }`,
            method: 'GET',
        });
        setPosts(data.posts);
        setTotalPosts(data.total); // Assuming the API returns the total count of posts
    };

    useEffect(() => {
        getPosts();
        getCategories();
        getUserAuthors();
    }, [currentPage]);

    const editPost = (postId) => {
        history.push(`/community/posts/edit/${postId}`);
    };

    const modalDisagreeHandler = () => {
        setModalOpen(false);
    };

    const modalAgreeHandler = async () => {
        try {
            await API({ url: `v1/community/admin/posts/${postId}`, method: 'DELETE' });
            await getPosts();
            setModalOpen(false);
        } catch (e) {
            console.error(e);
        }
    };

    const deletePost = (postId) => {
        setPostId(postId);
        setModalOpen(true);
    };

    const changeCategory = async (categoryName, postId) => {
        try {
            await API({
                url: 'v1/community/admin/posts/categories',
                method: 'POST',
                data: { categoryName, postId },
            });
            await getPosts();
        } catch (e) {
            console.error(e);
        }
    };

    const changeAuthor = async (authorName, postId) => {
        try {
            const author = adminAuthors.find((i) => i.nickName === authorName);

            console.log(adminAuthors);

            await API({
                url: 'v1/community/admin/posts/author',
                method: 'POST',
                data: {
                    author: {
                        id: author.id,
                        image_link: author.photo_url,
                        name: author.nickName,
                        bio: author.bio,
                    },
                    postId,
                },
            });
            await getPosts();
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <div className={styles.container}>
            <Title level={2}>Posts</Title>
            <Button
                style={{ marginTop: '11px', marginLeft: '15px' }}
                type="primary"
                icon={<PlusCircleOutlined />}
                onClick={() => history.push('/community/posts/create')}
            >
                Create Post
            </Button>

            <Table
                dataSource={posts}
                rowKey="id"
                pagination={{
                    current: currentPage,
                    pageSize: postsPerPage,
                    total: totalPosts,
                    onChange: (page) => setCurrentPage(page),
                }}
                scroll={{ x: 1800 }}
            >
                <Column title="Id" dataIndex="id" key="id" />
                <Column
                    title="Category"
                    key="category"
                    render={(text, record) => (
                        <Select
                            defaultValue={record.category_name || ''}
                            onChange={(value) => changeCategory(value, record.id)}
                        >
                            {categories.map((item, index) => (
                                <Select.Option key={index} value={item}>
                                    {item}
                                </Select.Option>
                            ))}
                        </Select>
                    )}
                />
                <Column
                    title="Author"
                    key="author"
                    render={(text, record) => {
                        console.log(record);
                        return (
                            <Select
                                style={{ width: 200 }}
                                defaultValue={record.user_data.name || ''}
                                onChange={(value) => {
                                    console.log(value);
                                    changeAuthor(value, record.id);
                                }}
                            >
                                {adminAuthors.map((item, index) => (
                                    <Select.Option key={index} value={item.nickName}>
                                        {item.nickName}
                                    </Select.Option>
                                ))}
                            </Select>
                        );
                    }}
                />
                <Column title="Sender's Name" dataIndex={['user_data', 'name']} key="senderName" />
                <Column
                    title="Photo"
                    key="photo"
                    render={(text, record) => (
                        <div>
                            {record.image_links?.length ? (
                                <div
                                    className={styles.photo}
                                    style={{ backgroundImage: `url(${record.image_links[0]})` }}
                                ></div>
                            ) : (
                                <div></div>
                            )}
                        </div>
                    )}
                />
                <Column
                    title="Message"
                    key="body"
                    render={(text, record) => (
                        <div className={styles.customTextarea}>{record.body}</div>
                    )}
                />
                <Column
                    title="Edit"
                    key="edit"
                    render={(text, record) => (
                        <Button onClick={() => editPost(record.id)} type="primary">
                            Edit
                        </Button>
                    )}
                />
                <Column
                    title="Delete"
                    key="delete"
                    render={(text, record) => (
                        <Button onClick={() => deletePost(record.id)} danger>
                            Delete
                        </Button>
                    )}
                />
            </Table>

            <Modal
                title="Confirm Deletion"
                open={isModalOpen}
                onOk={modalAgreeHandler}
                onCancel={modalDisagreeHandler}
            >
                <p>Are you sure you want to delete this post?</p>
            </Modal>
        </div>
    );
};

export default Posts;
