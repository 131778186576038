import { useEffect, useState } from 'react';
import { Table, Button, Tag } from 'antd';
import { useHistory } from 'react-router-dom';
import { API } from '../../helpers/api';
import styles from './Verification.module.scss';

const Challenges = () => {
    const history = useHistory();
    const [challenges, setChallenges] = useState([]);

    useEffect(() => {
        getChallenges();
    }, []);

    const getChallenges = async () => {
        const { data } = await API({ url: 'v1/challenges/admin/admin', method: 'GET' });

        data.challenges.forEach((item) => {
            if ('steps' in item) {
                item.type = 'goal';
            }
            if ('routine' in item) {
                item.type = 'habit';
            }
        });

        if (data && data.challenges) {
            setChallenges(data.challenges);
        }
    };

    const pendingChallenges = challenges.filter((item) => item.share === 'pending');

    const handleEdit = (type, id) => {
        const path = [history.location.pathname, type, id];
        history.push(path.join('/'));
    };

    const handleUserProfile = (userId) => {
        history.push(`/users/${userId}`);
    };

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            width: 50,
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: (text) => <div>{text?.toUpperCase()}</div>,
            width: 120,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: 400,
        },
        {
            title: 'Description',

            key: 'routine',
            width: 600,
            render: (item) => {
                return <div>{item.type === 'habit' ? item.routine : item.description}</div>;
            },
        },
        {
            title: 'Status',
            key: 'status',
            render: (item) => (
                <div className={styles.statusCont}>
                    <Tag color={item.share === 'pending' ? 'gold' : 'red'}>
                        {item.share === 'pending' ? 'Pending' : 'Rejected'}
                    </Tag>
                </div>
            ),
            width: 400,
        },
        {
            title: 'User Profile',
            dataIndex: 'user_id',
            key: 'user_id',
            render: (userId) => (
                <div className={styles.leftAligned}>
                    <Button type="link" onClick={() => handleUserProfile(userId)}>
                        View Profile
                    </Button>
                </div>
            ),
            width: 150,
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => {
                const type = record.type === 'goal' ? 'goal' : 'habit';
                return (
                    <Button
                        type="primary"
                        icon={<i className="fas fa-pencil-alt" />}
                        onClick={() => handleEdit(type, record.id)}
                    >
                        Open
                    </Button>
                );
            },
            width: 80,
        },
    ];

    return (
        <div>
            <h2>Awaiting verification</h2>
            <Table
                dataSource={pendingChallenges}
                columns={columns}
                rowKey="id"
                pagination={false}
                bordered
                style={{ width: '100%' }}
                scroll={{ x: true }}
                rowClassName={(record) => styles.tableRow}
            />

            <br />
            <br />
            <br />
            <br />
        </div>
    );
};

export default Challenges;
