import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Input, Button, message } from 'antd';
import { API } from '../../../helpers/api';
import { getLastUrlPath } from '../../../helpers/getLastUrlPath';

const EditTemplate = () => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const history = useHistory();

    const getTemplate = () => {
        API({
            url: `v1/journal/admin/templates/${getLastUrlPath()}`,
            method: 'GET',
        }).then(({ data }) => {
            setTitle(data.title);
            setDescription(data.description);
        });
    };

    useEffect(() => {
        getTemplate();
    }, []);

    const changeTemplate = async () => {
        await API({
            url: `v1/journal/admin/templates/`,
            method: 'PUT',
            data: {
                id: Number(getLastUrlPath()),
                title,
                description,
            },
        });

        message.success('Template updated successfully');
        history.push('/journal/list');
    };

    return (
        <div>
            <h2>Edit Template</h2>
            <Form layout="vertical">
                <Form.Item label="Title" required>
                    <Input value={title} onChange={(e) => setTitle(e.target.value)} />
                </Form.Item>
                <Form.Item label="Description">
                    <Input.TextArea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        rows={6}
                    />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" onClick={changeTemplate}>
                        Change
                    </Button>
                    <Button style={{ marginLeft: '8px' }} onClick={history.goBack}>
                        Cancel
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default EditTemplate;
