import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Input, Button, Typography, message } from 'antd';
import { API } from '../../../helpers/api';

const { Title } = Typography;

const CreateInspiration = () => {
    const [title, setTitle] = useState('');
    const [subtitle, setSubtitle] = useState('');
    const [titleError, setTitleError] = useState(false);
    const history = useHistory();

    const validateFields = () => {
        setTitleError(false);

        if (!title) {
            setTitleError(true);
            return false;
        }

        return true;
    };

    const createInspiration = async () => {
        if (!validateFields()) {
            return;
        }

        try {
            await API({
                url: 'v1/journey/admin/inspiration',
                method: 'POST',
                data: {
                    title,
                    body: subtitle,
                },
            });
            message.success('Inspiration created successfully!');
            history.push('/inspiration/list');
        } catch (error) {
            message.error('An error occurred while creating inspiration.');
        }
    };

    return (
        <div>
            <Title level={2}>Create Inspiration</Title>
            <Form layout="vertical">
                <Form.Item label="Author" required>
                    <Input
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        onBlur={validateFields}
                    />
                    {titleError && <div style={{ color: '#ca2d2d' }}>This field is required</div>}
                </Form.Item>

                <Form.Item label="Text">
                    <Input.TextArea
                        style={{ width: '100%' }}
                        rows={10}
                        value={subtitle}
                        onChange={(e) => setSubtitle(e.target.value)}
                    />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" onClick={createInspiration}>
                        Create
                    </Button>
                    <Button style={{ marginLeft: '10px' }} onClick={() => history.goBack()}>
                        Cancel
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default CreateInspiration;
