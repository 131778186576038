export const BASE_URL = process.env.REACT_APP_BASE_URL;
// export const BASE_URL = 'https://api-dev.confyday.com/';
export const BASE_URL_YC = 'https://api.wisme.app/';

export const DURAITIONS = ['month', 'week', 'long term', 'day', 'year'];
export const TYPES_ENTITY = ['goal', 'habit'];
export const WELLNESS = [
    'Emotional',
    'Environmental',
    'Physical',
    'Financial',
    'Intellectual',
    'Interpersonal',
    'Occupational',
    'Spiritual',
];
export const TYPE_HABIT = ['build', 'quit'];

export const PERSONAL_NOTIFICATION_TYPES = {
    violationNotice: 'violationNotice',
};

export const VIOLATION_NOTICE = 'violationNotice';

export const STANDART_FIELD_LENGTH = 1000;
