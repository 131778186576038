import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Input, Table, notification, Empty, Popconfirm, Select } from 'antd';
import { API } from '../../helpers/api';
import { isNumber } from 'lodash';

// Enum для периодов подписки
const SubscribePeriod = {
    THREE_MONTHS: '3 months',
    SIX_MONTHS: '6 months',
    ONE_YEAR: '1 year',
    TWO_YEARS: '2 years',
    THREE_YEARS: '3 years',
    UNLIMITED: 'Unlimited',
};

const Promocodes = () => {
    const [promocodes, setPromocodes] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();
    const [editingPromocode, setEditingPromocode] = useState(null); // Состояние для редактирования

    // Функция для получения списка промокодов
    const fetchPromocodes = async () => {
        try {
            const response = await API({
                url: 'v1/promocodes', // Замените на правильный путь к вашему API
                method: 'GET',
            });
            setPromocodes(response.data.promocodes); // Предполагается, что ответ содержит массив промокодов
        } catch (error) {
            console.error('Error fetching promocodes:', error);
            notification.error({
                message: 'Ошибка',
                description: 'Не удалось загрузить промокоды.',
            });
        }
    };

    const handleSavePromocode = async (values) => {
        try {
            if (isNumber(editingPromocode.id)) {
                await API({
                    url: `v1/promocodes/${editingPromocode.id}`,
                    method: 'PUT',
                    data: {
                        code: values.code,
                        count_activation: Number(values.count_activation),
                        subscribe_period: values.subscribe_period,
                    },
                });
                notification.success({
                    message: 'Успех',
                    description: 'Промокод успешно обновлен!',
                });
            } else {
                await API({
                    url: 'v1/promocodes',
                    method: 'POST',
                    data: {
                        code: values.code,
                        count_activation: Number(values.count_activation),
                        subscribe_period: values.subscribe_period,
                    },
                });
                notification.success({
                    message: 'Успех',
                    description: 'Промокод успешно создан!',
                });
            }
            setIsModalVisible(false);
            form.resetFields();
            setEditingPromocode(null);
            fetchPromocodes();
        } catch (error) {
            console.error('Error saving promocode:', error);
            notification.error({
                message: 'Ошибка',
                description: 'Не удалось сохранить промокод.',
            });
        }
    };

    const handleDeletePromocode = async (code) => {
        try {
            await API({
                url: `v1/promocodes/delete/${code}`,
                method: 'DELETE',
            });
            notification.success({
                message: 'Успех',
                description: 'Промокод успешно удален!',
            });
            fetchPromocodes();
        } catch (error) {
            console.error('Error deleting promocode:', error);
            notification.error({
                message: 'Ошибка',
                description: 'Не удалось удалить промокод.',
            });
        }
    };

    const showModal = (promocode = null) => {
        if (promocode) {
            setEditingPromocode(promocode);
            form.setFieldsValue({
                code: promocode.code,
                count_activation: promocode.count_activation,
                subscribe_period: promocode.subscribe_period,
            });
        } else {
            setEditingPromocode(null);
        }
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        form.resetFields();
        setEditingPromocode(null);
    };

    useEffect(() => {
        fetchPromocodes();
    }, []);

    const columns = [
        {
            title: 'Код',
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: 'Количество активаций',
            dataIndex: 'count_activation',
            key: 'count_activation',
        },
        {
            title: 'Период подписки',
            dataIndex: 'subscribe_period',
            key: 'subscribe_period',
        },
        {
            title: 'Действия',
            key: 'actions',
            render: (_, record) => (
                <div>
                    <Button
                        type="primary"
                        onClick={() => showModal(record)}
                        style={{ marginRight: '8px' }}
                    >
                        Редактировать
                    </Button>
                    <Popconfirm
                        title="Вы уверены, что хотите удалить этот промокод?"
                        onConfirm={() => handleDeletePromocode(record.code)}
                        okText="Да"
                        cancelText="Нет"
                    >
                        <Button type="danger">Удалить</Button>
                    </Popconfirm>
                </div>
            ),
        },
    ];

    return (
        <div>
            <h2>Promocodes</h2>

            <Button type="primary" onClick={showModal} style={{ marginBottom: '20px' }}>
                Create promocode
            </Button>

            {promocodes.length === 0 ? (
                <Empty description="Промокоды не найдены" />
            ) : (
                <Table
                    dataSource={promocodes}
                    columns={columns}
                    rowKey="id"
                    pagination={{ pageSize: 5 }}
                />
            )}

            <Modal
                title={editingPromocode ? 'Редактировать промокод' : 'Создать новый промокод'}
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={null}
            >
                <Form form={form} onFinish={handleSavePromocode}>
                    <Form.Item
                        label="Код промокода"
                        name="code"
                        rules={[{ required: true, message: 'Пожалуйста, введите код промокода!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Количество активаций"
                        name="count_activation"
                        rules={[
                            {
                                required: true,
                                message: 'Пожалуйста, введите количество активаций!',
                            },
                        ]}
                    >
                        <Input type="number" min={1} />
                    </Form.Item>

                    <Form.Item
                        label="Период подписки"
                        name="subscribe_period"
                        rules={[
                            { required: true, message: 'Пожалуйста, выберите период подписки!' },
                        ]}
                    >
                        <Select placeholder="Выберите период подписки">
                            {Object.entries(SubscribePeriod).map(([key, value]) => (
                                <Select.Option key={key} value={value}>
                                    {value}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" block>
                            {editingPromocode ? 'Обновить' : 'Создать'}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default Promocodes;
